import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';

import { AuthenticatedLookbookPath } from '../HOC/AuthenticatedLookbookPath';
import { AuthenticatedPath } from '../HOC/AuthenticatedPath';
import { GenericLinkHandler } from '../HOC/GenericLinkHandler';
import { SelectionLoader } from '../HOC/SelectionLoader';
import { UnauthenticatedPath } from '../HOC/UnauthenticatedPath';
import { compiledPaths, paths } from '../paths';

import { Account } from './account/Account';
import { AccountDetails } from './accounts/AccountDetails';
import { AccountList } from './accounts/AccountList';
import { LinkExpiredPage } from './authPage/LinkExpiredPage';
import { Checkout } from './checkout/Checkout';
import { CheckoutFailure, CheckoutPaymentFailure } from './checkout/checkoutFailure';
import { CheckoutSuccess } from './checkout/CheckoutSuccess';
import { PaymentPending } from './checkout/PaymentPending';
import { PaymentResult } from './checkout/PaymentResult';
import { InvoicePendingPage } from './invoices/InvoicePendingPage';
import { Invoices } from './invoices/Invoices';
import { ProcessingPaymentPage } from './invoices/ProcessingPaymentPage';
import { Linesheets } from './linesheets/Linesheets';
import { Lookbook } from './lookbook/Lookbook';
import { LookbookProductsPage } from './lookbook/LookbookProductsPage';
import OrderDetails from './orders/OrderDetails';
import { Orders } from './orders/Orders';
import { Selections } from './orders/Selections';
import { Page } from './pages/page';
import { PagesList } from './pagesList/PagesList';
import { AllProducts } from './products/AllProducts';
import { LookbookProductDetailsPage } from './products/product-details/LookbookProductDetailsPage';
import { Settings } from './settings/Settings';
import { RedirectOrderSheet } from './various/Redirects';
import { ChangePasswordPage, ForgotPasswordPage, LoginPage, WelcomePage } from './authPage';
import { Cms, CmsPreview } from './cms';
import { Home } from './home';
import { InvoiceDetailsPage } from './invoices';
import { NoMatch } from './NoMatch';
import { Template } from './Template';
import { Translations } from './translations';

const GuardedProducts = GenericLinkHandler(SelectionLoader(AllProducts));
const GuardedCheckout = SelectionLoader(Checkout);
const GuardedCheckoutSuccess = SelectionLoader(CheckoutSuccess);
const GuardedOrderDetails = SelectionLoader(OrderDetails);
const GuardedPage = GenericLinkHandler(SelectionLoader(Page));
const GenericHome = GenericLinkHandler(Home);

export const Routes = () => (
  <Template>
    <ReactRoutes>
      <Route
        path={paths.LOGIN}
        element={
          <UnauthenticatedPath>
            <LoginPage />
          </UnauthenticatedPath>
        }
      />
      <Route
        path={paths.PASSWORD_FORGOT}
        element={
          <UnauthenticatedPath>
            <ForgotPasswordPage />
          </UnauthenticatedPath>
        }
      />
      <Route
        path={paths.PASSWORD_CHANGE}
        element={
          <UnauthenticatedPath>
            <ChangePasswordPage />
          </UnauthenticatedPath>
        }
      />
      <Route
        path={paths.WELCOME}
        element={
          <UnauthenticatedPath>
            <WelcomePage />
          </UnauthenticatedPath>
        }
      />

      <Route
        path={paths.PAGES_LIST}
        element={
          <AuthenticatedPath limitAccess={['admin']}>
            <PagesList />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.PAGES}
        element={
          <AuthenticatedPath>
            <GuardedPage />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.CMS_PREVIEW}
        element={
          <AuthenticatedPath limitAccess={['admin']} hideHeader>
            <CmsPreview />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.CMS_EDITOR}
        element={
          <AuthenticatedPath limitAccess={['admin']} hideHeader>
            <Cms />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.ACCOUNT}
        element={
          <AuthenticatedPath>
            <Account />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.ACCOUNTS}
        element={
          <AuthenticatedPath limitAccess={['admin', 'seller']}>
            <AccountList />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.ACCOUNT_DETAILS}
        element={
          <AuthenticatedPath limitAccess={['admin', 'seller']}>
            <AccountDetails />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.CHECKOUT_ORDER}
        element={
          <AuthenticatedPath>
            <GuardedCheckout />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.CHECKOUT_FAILURE}
        element={
          <AuthenticatedPath>
            <CheckoutFailure />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.CHECKOUT_SUCCESS}
        element={
          <AuthenticatedPath>
            <GuardedCheckoutSuccess />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.LOOKBOOK}
        element={
          <AuthenticatedPath limitAccess={['admin', 'seller']}>
            <Lookbook />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.LOOKBOOK_SINGLE}
        element={
          <AuthenticatedLookbookPath>
            <LookbookProductsPage />
          </AuthenticatedLookbookPath>
        }
      />
      <Route
        path={paths.LOOKBOOK_SINGLE_PRODUCT}
        element={
          <AuthenticatedLookbookPath>
            <LookbookProductDetailsPage />
          </AuthenticatedLookbookPath>
        }
      />

      <Route
        path={paths.ORDERSHEETS}
        element={
          <AuthenticatedPath>
            <RedirectOrderSheet />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.SELECTIONS}
        element={
          <AuthenticatedPath>
            <Selections />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.ORDERS}
        element={
          <AuthenticatedPath>
            <Orders />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.ORDER_DETAILS}
        element={
          <AuthenticatedPath>
            <GuardedOrderDetails />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.PRODUCTS_ORDER}
        element={
          <AuthenticatedPath>
            <GuardedProducts />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.INVOICES}
        element={
          <AuthenticatedPath limitAccess={['buyer']}>
            <Invoices />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.INVOICE_DETAILS}
        element={
          <AuthenticatedPath>
            <InvoiceDetailsPage />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.INVOICE_PENDING}
        element={
          <AuthenticatedPath>
            <InvoicePendingPage />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.PROCESSING_PAYMENT}
        element={
          <AuthenticatedPath>
            <ProcessingPaymentPage />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.ORDER_PAYMENT_RESULT}
        element={
          <AuthenticatedPath>
            <PaymentResult />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.ORDER_PAYMENT_FAILURE}
        element={
          <AuthenticatedPath>
            <CheckoutPaymentFailure />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.ORDER_PAYMENT_PENDING}
        element={
          <AuthenticatedPath>
            <PaymentPending />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.ROOT}
        element={
          <AuthenticatedPath limitAccess={['admin', 'seller']} fallbackPath={compiledPaths.HOME({})}>
            <Navigate to={paths.SELECTIONS} replace />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.HOME}
        element={
          <AuthenticatedPath>
            <GenericHome />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.SETTINGS}
        element={
          <AuthenticatedPath limitAccess={['admin']}>
            <Settings />
          </AuthenticatedPath>
        }
      />
      <Route
        path={paths.TRANSLATIONS}
        element={
          <AuthenticatedPath limitAccess={['admin']}>
            <Translations />
          </AuthenticatedPath>
        }
      />

      <Route
        path={paths.LINESHEETS}
        element={
          <AuthenticatedPath limitAccess={['admin', 'seller']}>
            <Linesheets />
          </AuthenticatedPath>
        }
      />

      <Route path={paths.LINK_EXPIRED} element={<LinkExpiredPage />} />
      <Route path="/settings/customization" element={<Navigate to={paths.SETTINGS} replace />} />
      <Route path="/settings/navigation" element={<Navigate to={paths.SETTINGS} replace />} />
      <Route path="/settings/translations" element={<Navigate to={paths.TRANSLATIONS} replace />} />
      <Route path="/account/info" element={<Navigate to={paths.ACCOUNT} replace />} />
      <Route path="/account/password" element={<Navigate to={paths.ACCOUNT} replace />} />
      <Route path="*" element={<NoMatch />} />
    </ReactRoutes>
  </Template>
);
